<template>
  <div>
    <v-autocomplete v-bind="$attrs" label="Kapcsolat" :error-messages="errorMessages" v-model="connection_id"
      :items="connections" item-text="name" item-value="element_id" filled
      :value-comparator="$config.comparators.isEqual" append-outer-icon="mdi-plus"
      @click:append-outer="showConnectionDialog = true" />

    <connection-dialog v-model="showConnectionDialog" @save="input" />
  </div>
</template>

<script>
import ConnectionDialog from '@/components/ConnectionDialog.vue';

export default {
  inheritAttrs: false,

  components: {
    ConnectionDialog,
  },

  props: ['errors', 'errorMessages'],

  data() {
    return {
      connection_id: null,
      showConnectionDialog: false,
      connections: [],
    }
  },

  watch: {
    connection_id() {
      this.$emit('input', this.connection_id);
    },
  },

  async mounted() {
    await this.fetchConnections();
  },

  methods: {
    async fetchConnections() {
      const response = await this.$http.get('connections/list');
      this.connections = response.connections;
    },

    async input(connection) {
      await this.fetchConnections();
      this.connection_id = connection.element_id;
      this.$emit('input', this.connection_id);
    },
  },
}
</script>