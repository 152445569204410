<template>
  <div class="aspect-video position-relative" id="video-wrapper">
    <video ref="video" controls></video>
    <div id="video-info">
      <!-- <span v-if="message" v-html="message"></span> -->
      <div v-if="loading.value" class="mt-2 text-sm absolute mx-auto text-center">
        <v-progress-circular indeterminate size="48" />
        <div class="mt-2">
          Betöltés
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';

const retryPause = 10000;

const video = ref();
const message = ref('');
const loading = ref(true);

const props = defineProps({
  streamUrl: String
});

let url;

let pc = null;
let restartTimeout = null;
let sessionUrl = '';
let offerData = '';
let queuedCandidates = [];
let defaultControls = false;

const setMessage = (str) => {
  if (str !== '') {
    video.value.controls = false;
  } else {
    video.value.controls = defaultControls;
  }
  // message.value = str;
};

const unquoteCredential = (v) => (
  JSON.parse(`"${v}"`)
);

const linkToIceServers = (links) => (
  (links !== null) ? links.split(', ').map((link) => {
    const m = link.match(/^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i);
    const ret = {
      urls: [m[1]],
    };

    if (m[3] !== undefined) {
      ret.username = unquoteCredential(m[3]);
      ret.credential = unquoteCredential(m[4]);
      ret.credentialType = 'password';
    }

    return ret;
  }) : []
);

const parseOffer = (offer) => {
  const ret = {
    iceUfrag: '',
    icePwd: '',
    medias: [],
  };

  for (const line of offer.split('\r\n')) {
    if (line.startsWith('m=')) {
      ret.medias.push(line.slice('m='.length));
    } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
      ret.iceUfrag = line.slice('a=ice-ufrag:'.length);
    } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
      ret.icePwd = line.slice('a=ice-pwd:'.length);
    }
  }

  return ret;
};

const enableStereoOpus = (section) => {
  let opusPayloadFormat = '';
  let lines = section.split('\r\n');

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
      opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
      break;
    }
  }

  if (opusPayloadFormat === '') {
    return section;
  }

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
      if (!lines[i].includes('stereo')) {
        lines[i] += ';stereo=1';
      }
      if (!lines[i].includes('sprop-stereo')) {
        lines[i] += ';sprop-stereo=1';
      }
    }
  }

  return lines.join('\r\n');
};

const editOffer = (offer) => {
  const sections = offer.sdp.split('m=');

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.startsWith('audio')) {
      sections[i] = enableStereoOpus(section);
    }
  }

  offer.sdp = sections.join('m=');
};

const generateSdpFragment = (od, candidates) => {
  const candidatesByMedia = {};
  for (const candidate of candidates) {
    const mid = candidate.sdpMLineIndex;
    if (candidatesByMedia[mid] === undefined) {
      candidatesByMedia[mid] = [];
    }
    candidatesByMedia[mid].push(candidate);
  }

  let frag = 'a=ice-ufrag:' + od.iceUfrag + '\r\n'
    + 'a=ice-pwd:' + od.icePwd + '\r\n';

  let mid = 0;

  for (const media of od.medias) {
    if (candidatesByMedia[mid] !== undefined) {
      frag += 'm=' + media + '\r\n'
        + 'a=mid:' + mid + '\r\n';

      for (const candidate of candidatesByMedia[mid]) {
        frag += 'a=' + candidate.candidate + '\r\n';
      }
    }
    mid++;
  }

  return frag;
};

const loadStream = () => {
  requestICEServers();
};

const onError = (err) => {
  if (restartTimeout === null) {

    // setMessage(err + ', retrying in some seconds');
    message.value = err;
    loading.value = false;

    if (pc !== null) {
      pc.close();
      pc = null;
    }

    restartTimeout = window.setTimeout(() => {
      restartTimeout = null;
      loadStream();
    }, retryPause);

    if (sessionUrl) {
      fetch(sessionUrl, {
        method: 'DELETE',
      });
    }
    sessionUrl = '';

    queuedCandidates = [];
  }
};

const sendLocalCandidates = (candidates) => {
  fetch(sessionUrl + '' ?? '' ?? window.location.search, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/trickle-ice-sdpfrag',
      'If-Match': '*',
    },
    body: generateSdpFragment(offerData, candidates),
  })
    .then((res) => {
      switch (res.status) {
        case 204:
          break;
        case 404:
          throw new Error('Stream not found');
        case 400:
          throw new Error('Bad request');
        default:
          throw new Error(`Bad status code ${res.status}`);
      }

    })
    .catch((err) => {
      console.log(err);
      onError(err.toString());
    });
};

const onLocalCandidate = (evt) => {
  if (restartTimeout !== null) {
    return;
  }

  if (evt.candidate !== null) {
    if (sessionUrl === '') {
      queuedCandidates.push(evt.candidate);
    } else {
      sendLocalCandidates([evt.candidate]);
    }
  }
};

const onRemoteAnswer = (sdp) => {
  if (restartTimeout !== null) {
    return;
  }

  pc.setRemoteDescription(new RTCSessionDescription({
    type: 'answer',
    sdp,
  }));

  if (queuedCandidates.length !== 0) {
    sendLocalCandidates(queuedCandidates);
    queuedCandidates = [];
  }
};

const sendOffer = (offer) => {
  fetch(url + '' ?? window.location.search, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/sdp',
    },
    body: offer.sdp,
  })
    .then((res) => {
      switch (res.status) {
        case 201:
          break;
        case 404:
          throw new Error('stream not found');
        default:
          throw new Error(`bad status code ${res.status}`);
      }

      sessionUrl = new URL(res.headers.get('location').substring(1, res.headers.get('location').length), 'https://beleptetoadmin.sze.hu/mediamtx/').toString();
      // sessionUrl = 'https://beleptetoadmin.sze.hu/mediamtx/whep' + res.headers.get('location');
      console.log(sessionUrl)
      return res.text();
    })
    .then((sdp) => onRemoteAnswer(sdp))
    .catch((err) => {
      console.log(err);
      onError(err.toString());
    });
};

const createOffer = () => {
  pc.createOffer()
    .then((offer) => {
      editOffer(offer);
      offerData = parseOffer(offer.sdp);
      pc.setLocalDescription(offer);
      sendOffer(offer);
    });
};

const onConnectionState = () => {
  if (restartTimeout !== null) {
    return;
  }

  if (pc.iceConnectionState === 'disconnected') {
    onError('HÁLÓZATI HIBA');
  }
};

const onTrack = (evt) => {
  setMessage('');
  video.value.srcObject = evt.streams[0];
};

const requestICEServers = () => {
  fetch(url + '' ?? window.location.search, {
    method: 'OPTIONS',
  })
    .then((res) => {
      pc = new RTCPeerConnection({
        iceServers: linkToIceServers(res.headers.get('Link')),
        // https://webrtc.org/getting-started/unified-plan-transition-guide
        sdpSemantics: 'unified-plan',
      });

      const direction = 'sendrecv';
      pc.addTransceiver('video', { direction });
      pc.addTransceiver('audio', { direction });

      pc.onicecandidate = (evt) => onLocalCandidate(evt);
      pc.oniceconnectionstatechange = () => onConnectionState();
      pc.ontrack = (evt) => onTrack(evt);

      createOffer();
    })
    .catch((err) => {
      onError(err.toString());
      message.value = 'Csatlakozási hiba';
    });
};

const parseBoolString = (str, defaultVal) => {
  str = (str || '');

  if (['1', 'yes', 'true'].includes(str.toLowerCase())) {
    return true;
  }
  if (['0', 'no', 'false'].includes(str.toLowerCase())) {
    return false;
  }
  return defaultVal;
};

const loadAttributesFromQuery = () => {
  const params = new URLSearchParams('' ?? window.location.search);
  video.value.controls = parseBoolString(params.get('controls'), false);
  video.value.muted = parseBoolString(params.get('muted'), true);
  video.value.autoplay = parseBoolString(params.get('autoplay'), true);
  video.value.playsInline = parseBoolString(params.get('playsinline'), true);
  defaultControls = video.value.controls;
};

onMounted(() => {
  url = new URL('whep', props.streamUrl || '');

  video.value.addEventListener('play', () => {
    loading.value = false;
  });

  loadAttributesFromQuery();
  loadStream();
});

onBeforeUnmount(() => {
  // remove event listeners
  video.value.removeEventListener('play', () => {
    loading.value = false;
  });
});

</script>

<style scoped>
#video-wrapper {
  position: relative;
  min-height: 100px;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

#video-wrapper>video {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  display: block;
  object-fit: fill;
  aspect-ratio: 16 / 9;
}

#video-wrapper>div {
  backdrop-filter: blur(8px);
  background-color: #0006;
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 0;
}
</style>