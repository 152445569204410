var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', _vm._b({
    attrs: {
      "label": "Kapcsolat",
      "error-messages": _vm.errorMessages,
      "items": _vm.connections,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "append-outer-icon": "mdi-plus"
    },
    on: {
      "click:append-outer": function ($event) {
        _vm.showConnectionDialog = true;
      }
    },
    model: {
      value: _vm.connection_id,
      callback: function ($$v) {
        _vm.connection_id = $$v;
      },
      expression: "connection_id"
    }
  }, 'v-autocomplete', _vm.$attrs, false)), _c('connection-dialog', {
    on: {
      "save": _vm.input
    },
    model: {
      value: _vm.showConnectionDialog,
      callback: function ($$v) {
        _vm.showConnectionDialog = $$v;
      },
      expression: "showConnectionDialog"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }