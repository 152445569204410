var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": _vm.$route.name === 'NewCamera' ? 'Új kamera' : 'Kamera szerkesztése'
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "loading": _vm.loading
          },
          on: {
            "click": _vm.save
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1)];
      },
      proxy: true
    }])
  }), _vm.loading ? _c('loading-overlay') : _vm._e(), _c('v-card', {
    staticClass: "mb-6"
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cctv")]), _vm._v(" Alapadatok ")], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('connection-text-field', {
    attrs: {
      "hint": "A rendszámfelismeréshez szükséges",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.connection_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "connection_id", $$v);
      },
      expression: "form.connection_id"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Felhasználónév",
      "hint": "A rendszámfelismeréshez szükséges",
      "persistent-hint": "",
      "error-messages": _vm.errors.username,
      "filled": ""
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Jelszó",
      "type": "password",
      "hint": "A rendszámfelismeréshez szükséges",
      "persistent-hint": "",
      "error-messages": _vm.errors.password,
      "filled": ""
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "rows": 3,
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Épület szint",
      "error-messages": _vm.errors.building_level_id,
      "items": _vm.buildingLevels,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "disabled": !_vm.form.building_id,
      "persistent-hint": !_vm.form.building_id,
      "hint": _vm.form.building_id ? '' : 'Épület kötelező'
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 3,
      "sm": 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "filled": "",
      "type": "number"
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-television")]), _vm._v(" Élő nézet ")], 1), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "RTSP cím",
      "error-messages": _vm.errors.rtsp_url,
      "filled": "",
      "persistent-hint": "",
      "hint": "Mentés után frissül"
    },
    model: {
      value: _vm.form.rtsp_url,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rtsp_url", $$v);
      },
      expression: "form.rtsp_url"
    }
  }), _c('div', {
    staticClass: "relative",
    attrs: {
      "id": "lpr-overlay"
    }
  }, [_vm.isRtspUrlValid ? _c('LiveFeedWebrtc', {
    key: _vm.form.rtsp_url,
    attrs: {
      "stream-url": `https://beleptetoadmin.sze.hu/mediamtx/${_vm.form.element_id}/`
    }
  }) : _vm._e(), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "width": "1920",
      "height": "1080"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-vector-polygon-variant")]), _vm._v(" Rendszám zónák ")], 1), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Szín',
        value: 'color'
      }, {
        text: 'Poligon',
        value: 'polygon'
      }, {
        text: 'Sorompó',
        value: 'gate_id'
      }],
      "items": _vm.barriers,
      "blank-item-template": {
        name: '',
        polygon: ''
      }
    },
    scopedSlots: _vm._u([{
      key: `item.color`,
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c('v-sheet', {
          attrs: {
            "width": "20",
            "height": "20",
            "color": _vm.colors[index],
            "rounded": ""
          }
        })];
      }
    }, {
      key: `item.polygon`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "[[0,0], [0,0.4], [0.4,0.4], [0.4, 0]]",
            "hide-details": "",
            "filled": "",
            "dense": ""
          },
          model: {
            value: item.polygon,
            callback: function ($$v) {
              _vm.$set(item, "polygon", $$v);
            },
            expression: "item.polygon"
          }
        })];
      }
    }, {
      key: `item.gate_id`,
      fn: function (_ref3) {
        var item = _ref3.item,
          items = _ref3.items;
        return [_c('v-autocomplete', {
          attrs: {
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "placeholder": "Válassz",
            "hide-details": "",
            "filled": "",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: item.gate_id,
            callback: function ($$v) {
              _vm.$set(item, "gate_id", $$v);
            },
            expression: "item.gate_id"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.lpr_zones,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lpr_zones", $$v);
      },
      expression: "form.lpr_zones"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }