<template>
  <div>
    <title-row :title="$route.name === 'NewCamera' ? 'Új kamera' : 'Kamera szerkesztése'">
      <template #buttons>
        <title-row-button @click="save" :loading="loading">
          <v-icon left>mdi-check</v-icon>
          Mentés
        </title-row-button>
      </template>
    </title-row>

    <loading-overlay v-if="loading" />

    <v-card class="mb-6">
      <v-card-title>
        <v-icon left>mdi-cctv</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-text-field label="Megnevezés" :error-messages="errors.name" v-model="form.name" filled />

            <connection-text-field v-model="form.connection_id" hint="A rendszámfelismeréshez szükséges"
              persistent-hint />

            <v-text-field label="Felhasználónév" hint="A rendszámfelismeréshez szükséges" persistent-hint
              :error-messages="errors.username" v-model="form.username" filled />

            <v-text-field label="Jelszó" type="password" hint="A rendszámfelismeréshez szükséges" persistent-hint
              :error-messages="errors.password" v-model="form.password" filled />

            <!-- <v-text-field label="Stream szerver cím" :error-messages="errors.webrtc_stream_server_address"
              v-model="form.webrtc_stream_server_address" filled /> -->

            <!-- <v-text-field label="Stream azonosító" :error-messages="errors.webrtc_stream_id"
              v-model="form.webrtc_stream_id" filled /> -->

          </v-col>
          <v-col :cols="12" :md="6">

            <v-textarea label="Megjegyzés" :error-messages="errors.comment" v-model="form.comment" filled :rows="3"
              auto-grow />

          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="3" :sm="6">

            <v-autocomplete label="Épület" :error-messages="errors.building_id" v-model="form.building_id"
              :items="buildings" item-text="name" item-value="id" filled
              :value-comparator="$config.comparators.isEqual" />

          </v-col>
          <v-col :cols="12" :md="3" :sm="6">

            <v-autocomplete label="Épület szint" :error-messages="errors.building_level_id"
              v-model="form.building_level_id" :items="buildingLevels" item-text="name" item-value="id" filled
              :value-comparator="$config.comparators.isEqual" :disabled="!form.building_id"
              :persistent-hint="!form.building_id" :hint="form.building_id ? '' : 'Épület kötelező'" />

          </v-col>
          <v-col :cols="12" :md="3" :sm="6">

            <v-text-field label="X koordináta" :error-messages="errors.x_coord" v-model="form.x_coord" filled
              type="number" />

          </v-col>
          <v-col :cols="12" :md="3" :sm="6">

            <v-text-field label="Y koordináta" :error-messages="errors.y_coord" v-model="form.y_coord" filled
              type="number" />

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-television</v-icon>
            Élő nézet
          </v-card-title>
          <v-card-text>

            <v-text-field label="RTSP cím" :error-messages="errors.rtsp_url" v-model="form.rtsp_url" filled persistent-hint hint="Mentés után frissül" />

            <div id="lpr-overlay" class="relative">
              <LiveFeedWebrtc v-if="isRtspUrlValid" :key="form.rtsp_url"
                :stream-url="`https://beleptetoadmin.sze.hu/mediamtx/${form.element_id}/`" />
              <canvas ref="canvas" width="1920" height="1080" style="pointer-events: none;"></canvas>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-vector-polygon-variant</v-icon>
            Rendszám zónák
          </v-card-title>
          <v-multi-select-list :headers="[
            { text: 'Szín', value: 'color' },
            { text: 'Poligon', value: 'polygon' },
            { text: 'Sorompó', value: 'gate_id' }
          ]" :items="barriers" v-model="form.lpr_zones" :blank-item-template="{ name: '', polygon: '' }">
            <template #[`item.color`]="{ item, index }">
              <v-sheet width="20" height="20" :color="colors[index]" rounded></v-sheet>
            </template>
            <template #[`item.polygon`]="{ item }">
              <v-text-field v-model="item.polygon" placeholder="[[0,0], [0,0.4], [0.4,0.4], [0.4, 0]]" hide-details
                filled dense />
            </template>
            <template #[`item.gate_id`]="{ item, items }">
              <v-autocomplete :items="items" v-model="item.gate_id" item-text="name" item-value="element_id"
                placeholder="Válassz" hide-details filled dense clearable />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>

      <!-- <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-cog-outline</v-icon>
            Megfigyelt eszközök
          </v-card-title>
          <v-card-text>
            <v-multi-select-list :headers="[
              { text: 'Eszköz', value: 'element_id' },
              { text: 'Fő kamera', value: 'main_camera' },
            ]" :items="gates" v-model="form.elements" :blank-item-template="{ name: '', polygon: '' }">
              <template #[`item.element_id`]="{ item, items }">
                <v-autocomplete :items="items" v-model="item.id" item-text="name" item-value="element_id"
                  placeholder="Válassz" hide-details filled dense clearable>
                  <template #selection="{ item }">
                    <v-chip class="mr-2">{{ item.type.name }}</v-chip>
                    {{ item.name }}
                  </template>
                  <template #item="{ item }">
                    <v-chip class="mr-2">{{ item.type.name }}</v-chip>
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </template>
              <template #[`item.main_camera`]="{ item }">
                <v-switch v-model="item.main_camera" inset></v-switch>
              </template>
            </v-multi-select-list>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
const colors = [
  '#ff0000',
  '#ffff00',
  '#00ff00',
  '#00ffff',
  '#0000ff',
  '#ff00ff',
];

// import StreamPlayer from '@/components/StreamPlayerMSE.vue';
import LiveFeedWebrtc from '@/components/StreamPlayerWebRtc.vue';
import ConnectionTextField from '@/components/ConnectionTextField.vue';

export default {
  components: {
    // StreamPlayer,
    LiveFeedWebrtc,
    ConnectionTextField,
  },

  data() {
    return {
      errors: {},
      form: {
        lpr_zones: [],
      },
      showConnectionDialog: false,
      loading: false,
      colors,
    };
  },

  mounted() {
    this.fetchCamera();
  },

  watch: {
    'form.lpr_zones': {
      handler(val) {
        const canvas = this.$refs.canvas;
        if (!canvas) {
          return;
        }
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        let n = 0;
        for (const zone of this.form.lpr_zones) {
          let points = [];
          try {
            points = JSON.parse(zone.polygon);
          } catch (e) {
            continue;
          }

          console.log(points);
          context.fillStyle = colors[n] + '12';
          context.strokeStyle = colors[n] + '88';
          context.lineWidth = 10;
          context.beginPath();
          context.moveTo(points[0][0] * canvas.width, points[0][1] * canvas.height);
          for (let i = 1; i < points.length; i++) {
            context.lineTo(Math.round(points[i][0] * canvas.width), Math.round(points[i][1] * canvas.height));
          }
          context.closePath();
          context.fill();
          context.stroke();
          n++;
        }
      },
      deep: true
    }
  },

  methods: {
    async fetchCamera() {
      this.loading = true;
      const response = await this.$http.get(`cameras/details/${this.$route.params.id || 0}`);
      this.loading = false;
      this.form = response.camera;
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('cameras/save/', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'CameraEditor') {
            this.$router.push({
              name: 'CameraEditor',
              params: { id: response.camera.element_id },
            });
          }
          this.fetchCamera();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    isRtspUrlValid() {
      try {
        new URL(this.form.rtsp_url);
        return true;
      } catch (e) {
        return false;
      }
    }
  },

  asyncComputed: {
    controllers: {
      async get() {
        const response = await this.$http.post('controllers/list');
        return response.controllers;
      },
      default: [],
      lazy: true,
    },

    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list');
        return response.buildings;
      },
      default: [],
      lazy: true,
    },

    buildingLevels: {
      async get() {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        return response.building_levels;
      },
      default: [],
      lazy: true,
    },

    barriers: {
      async get() {
        const response = await this.$http.post('barriers/list', { limit: 9999 });
        return response.barriers;
      },
      default: [],
    },

    gates: {
      async get() {
        const response = await this.$http.post('elements/list', {
          model_names: ['Barriers', 'Doors', 'Turnstiles'],
          sortBy: 'Elements.type_id, Elements.id',
        });
        return response.elements;
      },
      default: [],
    },
  },
};
</script>

<style lang="scss">
#lpr-overlay {
  position: relative;

  &>canvas {
    position: absolute;
    inset: 0;
    width: 100%;
    aspect-ratio: 16/9;
    z-index: 1;
  }
}
</style>
